<template>
  <div class="home">
    <!--  头部  -->
    <home-header></home-header>
    <!--  内容区域  -->
    <main style="background: #fafafa;">

      <!--   主图   -->
      <section id="home" ref="mainDiv" style="padding-top: 100px">

        <home-main-home :height="divHeight / 2"></home-main-home>

      </section>

      <!--  产品    -->
      <section id="services">

        <home-main-for-services></home-main-for-services>

      </section>


      <section id="chanpinyufuwu">

        <HomeMainServices></HomeMainServices>

      </section>


      <!--   价格介绍   -->
      <section id="solution">

        <HomeMainPriceIntroduction></HomeMainPriceIntroduction>

      </section>

      <section id="main1351">

        <HomeMain1351></HomeMain1351>

      </section>
      <!--  合作品牌   -->
      <section id="partner">

        <home-main-partner :height="divHeight"></home-main-partner>

      </section>

      <!--客户案例-->
      <section id="portfolio">

        <HomeMainPortfolio></HomeMainPortfolio>

      </section>

      <!--   关于我们   -->
      <section id="about">

        <home-main-about :height="divHeight" :b-is-mobile="bIsMobile"></home-main-about>

      </section>

      <!--   公司简介   -->
      <section id="CompanyInfo">

        <home-main-company :height="divHeight" :isMobile="bIsMobile"></home-main-company>

      </section>

      <!--   下载   -->
      <section id="download">

        <HomeMainDownload></HomeMainDownload>

      </section>

    </main>

    <a id="gzh">
      <div class="gzh_div">
        <span class="gzh_div_phone">扫码关注</span>
        公众号
        <span class="gzh_div_phone">获取更多信息</span>
        <img :src="require('../assets/gzh.png')" alt=""
             style="width: 80%;">
      </div>
    </a>

    <div style="cursor:pointer;">
      <a id="btnBox" href="#home" v-smooth-scroll>
        <img class="btn_Img" :src="require('../assets/top-filling.png')" alt="" @mouseenter="isShowImg = true;">
      </a>
    </div>

    <div style="position: fixed;bottom: 50px;right: calc(100% - 95%);">
      <img class="btn_Img" :src="require('../assets/gzh.png')" alt=""
           style="width: 200px;height: 200px" v-show="showImgIndex === 1">
      <img class="btn_Img" :src="QRCode.huyuan" alt=""
           style="width: 200px;height: 200px" v-show="showImgIndex === 2">
      <img class="btn_Img" :src="QRCode.yuangong" alt=""
           style="width: 200px;height: 200px" v-show="showImgIndex === 3">
    </div>
    <home-footer></home-footer>
    <!--  底部  -->
  </div>


</template>

<script>
import homeHeader from "@/components/HomeHeader";
import homeFooter from "@/components/HomeFooter";
import homeMainHome from "@/components/HomeMain/Home.vue";
import homeMainAbout from "@/components/HomeMain/About.vue";
import homeMainBlog from "@/components/HomeMain/Blog.vue";
import homeMainContact from "@/components/HomeMain/Contact.vue";
import homeMainPortfolio from "@/components/HomeMain/HomeMainPortfolio";
import HomeMainPortfolio from "@/components/HomeMain/HomeMainPortfolio";
import homeMainPartner from "@/components/HomeMain/Partner.vue";
import HomeMainCompany from "@/components/HomeMain/Company.vue";
import HomeMainPriceIntroduction from "@/components/HomeMain/PriceIntroduction.vue";
import HomeMainForPortfolio from "@/components/HomeMain/Portfolio.vue";
import HomeMain1351 from "@/components/HomeMain/感动式服务.vue";
import HomeMainDownload from "@/components/HomeMain/Download.vue";
import HomeMainServices from "@/components/HomeMain/HomeMainServices";
import HomeMainForServices from "@/components/HomeMain/Services.vue";


export default {
  name: 'HomeView',
  components: {
    homeHeader,
    homeFooter,
    homeMainHome,
    homeMainAbout,
    homeMainBlog,
    homeMainContact,
    homeMainPortfolio,
    homeMainPartner,
    HomeMainCompany,
    HomeMainPriceIntroduction,
    HomeMainForPortfolio,
    HomeMainForServices,
    HomeMainPortfolio,
    HomeMain1351,
    HomeMainDownload,
    HomeMainServices
  },
  data() {
    return {
      isShowHeader: true,
      btnFlag: false,
      divHeight: 0,
      pageHeight: 0,
      scrollFLag: true,
      showImgIndex: 0,
      isShowImg: false,
      btnImg: {
        img: "http://www2.jmtcsoft.com/img/2022-10-9/gzh.png",
        // img2: "http://www2.jmtcsoft.com/img/2022-10-9/huyuan.png",
        // img3: "http://www2.jmtcsoft.com/img/2022-10-9/yuangong.png",
        img4: "http://www2.jmtcsoft.com/img/2022-10-9/top-filling.png"
      },
      QRCode: {
        gzh: "http://www2.jmtcsoft.com/img/2022-10-9/gzh.png",
        // huyuan: "http://www2.jmtcsoft.com/img/2022-10-9/huyuan.png",
        // yuangong: "http://www2.jmtcsoft.com/img/2022-10-9/yuangong.png"
      },
      bIsMobile: false,
      nowWidth: 0,
      nowHeight: 0,
      isShow: false
    }
  },
  methods: {
    TestClick(){
      this.$router.push({path: "/userSaid"})
    },
    test() {
      this.$router.replace({path: "/system"})
    },
    _isMobile() {
      // console.log(navigator.userAgent)
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    },
    getDivHeight() {
      // 判断横竖屏
      let width = document.documentElement.clientWidth;
      this.nowWidth = width;
      // console.log(width)
      let height = document.documentElement.clientHeight;
      this.nowHeight = height;
      // console.log(height)
      // alert(width+"宽高"+height)
      if (width > height) {
        this.isShow = false;
      } else {
        if (!this._isMobile()) {
          this.isShow = true;
        }
      }
      this.divHeight = window.innerHeight;
    },
    isScroll() {
      const top = document.documentElement.scrollTop
      this.isShowHeader = top <= 100;
    },
    backTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this
      that.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (that.scrollTop > 60) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },
    // 回到顶部操作
    goBack() {
      document.documentElement.scrollTop = 0;
    },
    //监听回到顶部按钮距浏览器顶部的距离
    scrollToTopByGoBack() {
      // 获取dom元素id
      let topBtn = document.getElementById("btnBox");
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // 判断距离顶部多少显示回到顶部图标
      if (scrollTop > 500) {
        topBtn.style.display = "block";
      } else if (scrollTop < 500) {
        topBtn.style.display = "none";
      }
    },
    //离开该页面需要移除这个监听的事件，不然会报错;
    destroyed() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
    changeIndex(v) {
      this.showImgIndex = v;
    },
    renderResize() {
      // 做页面适配
      // 注意：renderResize方法执行时虚拟dom尚未渲染挂载，如果要操作vue实例，最好在this.$nextTick()里进行。
    }
  },
  mounted() {
    window.addEventListener('resize', this.getDivHeight)
    window.addEventListener('scroll', this.isScroll)
    // 侦听事件并处理相应函数
    window.addEventListener("scroll", this.scrollToTopByGoBack);
    // window.addEventListener("resize", this.renderResize, false);
    this.bIsMobile = !!this._isMobile();
  }
  ,
  unmounted() {
    window.removeEventListener('resize', this.getDivHeight, false)
    window.removeEventListener('scroll', this.isScroll)
  }
  ,
  created() {
    this.wheelCount = 0;
  }
}
</script>

<style>
section {
  background: #fafafa !important;
  padding: 0;
}

#btnBox {
  position: fixed;
  bottom: 50px;
  right: 0;
  font-size: 30px;
  display: none;
  text-decoration: none;
}


.btn_Img {
  display: block;
  width: 40px;
  height: 40px;
}

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  #btnBox {
    position: fixed;
    bottom: 90px;
    right: 0;
    font-size: 30px;
    display: none;
    text-decoration: none;
  }



  #services {
    padding: 0;
  }

  h2.section-title {
    font-size: 30px;
  }

  h2.section-title:after, .h2.section-title:after {
    display: block;
    content: '';
    width: 100%;
    /*height: 50px;*/
    /* background: url(../img/svg/separator.svg) center center no-repeat; */
    margin-top: 0;
  }

  h2.section-title, .h2.section-title {
    color: black;
  }

  #partner {
    padding: 0;
  }

  #gzh {
    position: fixed;
    bottom: 200px;
    right: 0;
    font-size: 30px;
    display: block;
    text-decoration: none;
  }

  .gzh_div {
    width: 100px;
    height: 10px;
    margin-bottom: 30px;
    margin-left: 30px;
    font-size: 14px;
    color: #000000;
    text-align: center;
  }

  .gzh_div_phone {
    display: none;
  }

}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {

  #gzh {
    position: fixed;
    bottom:10px;
    right: 0;
    font-size: 30px;
    display: block;
    text-decoration: none;
  }

  .gzh_div {
    width: 100px;
    height: 200px;
    font-size: 10px;
    color: #000000;
    text-align: center;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {

  #partner {
    padding: 45px 0 0;
  }

  #main1351 {
    padding: 45px 0 0;
  }

  #download {
    padding: 45px 0 0;
  }

  #gzh {
    position: fixed;
    bottom: 10px;
    right: 0;
    font-size: 30px;
    display: block;
    text-decoration: none;
  }

  .gzh_div {
    width: 100px;
    height: 200px;
    font-size: 10px;
    color: #000000;
    text-align: center;
  }
}

</style>
