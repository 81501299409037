import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomeCase from "@/views/HomeCase";
import customerView from "@/views/costume/customerView";
import systemIntro from "@/views/intro/systemIntro";
import HomeView from "@/views/HomeView";
import chaguanjicaiIntro from "@/views/intro/chaguanjicaiIntro";
import rencaipeixunIntro from "@/views/intro/rencaipeixunIntro";
import tuoguanyunyingIntro from "@/views/intro/tuoguanyunyingIntro";
import UserSaid from "@/views/UserSaid.vue";


const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    }, {
        path: '/case',
        name: "case",
        component: HomeCase
    }, {
        //客户案例
        path: '/customer',
        name: 'customerView',
        component: customerView
    }, {
        //数字化系统详情
        path: '/system',
        name: 'systemIntro',
        component: systemIntro
    }, {
        //茶馆集采详情
        path: '/chaguanjicaiIntro',
        name: 'chaguanjicaiIntro',
        component: chaguanjicaiIntro
    }, {
        //人才培训详情
        path: '/rencaipeixunIntro',
        name: 'rencaipeixunIntro',
        component: rencaipeixunIntro
    }, {
        //托管运营详情
        path: '/tuoguanyunyingIntro',
        name: 'tuoguanyunyingIntro',
        component: tuoguanyunyingIntro
    },
    {
        path: '/userSaid',
        name: 'userSaid',
        component: UserSaid
    }
]
const router = createRouter({
    //hash 模式
    // history: createWebHashHistory(),
    //html5 模式
    // history: createWebHashHistory(process.env.VUE_APP_URL),
    history: createWebHistory("/"),
    routes
})


export default router
