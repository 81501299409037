<template>
  <div>
    <div class="content">
      <!--  头部  -->
      <div class="content_top" style="margin-top: 30px">
        <div class="header">
          <div>金米天成茶楼茶馆管理平台</div>
        </div>
        <div class="secondTittle">
          <div>《用户说》</div>
        </div>
      </div>
      <div class="secondTittle" style="text-align: left;padding: 10px" >
<!--        金杯银杯不如用户的口碑，2023新年伊始，金米天成热情又可爱的用户朋友发来使用体验和问候，我们对此心存感激，正是因为大家的参与和支持，让我们有勇气，在茶业数字化的道路上，坚定前行。-->
<!--        在此依次展播，见证我们一起携手走过，未来我们将持续打磨产品，坚持用技术创新，为用户提供更高效和便捷的使用体验，不负信任。-->
        <div style="margin-top: 2px;text-indent: 2em;">金杯银杯不如用户的口碑，2023新年伊始，金米天成热情又可爱的用户朋友发来使用体验和问候。</div>
        <div style="margin-top: 2px;text-indent: 2em;">我们对此心存感激，有大家的参与和支持，让我们有勇气在茶业数字化的道路上，坚定前行。</div>
        <div style="margin-top: 2px;text-indent: 2em;">在此依次展播，见证我们一起携手走过，未来我们将持续打磨产品，坚持用技术创新，为用户提供更高效和便捷的使用体验，不负信任。</div>
<!--        <div></div>-->
      </div>
      <div style="color: #a0a0a0;font-size: 12px;margin: 0 auto;text-align: left;padding: 15px">
        Tips: 陆续更新中，排名不分先后
      </div>
      <!--   内容区   -->
      <div>
        <!--    卡片    -->
        <div style="text-align: center;min-height: 150px" v-for="(item,index) in videoList" :key="index">
          <!--  头部  -->
          <div class="content_top">
            <div class="secondTittle">
              <div>{{ item.title }}</div>
            </div>
            <div class="line"></div>
          </div>
          <div
              style="padding: 15px;text-align: left;margin: 0 auto;text-indent: 2em;font-size: 16px;color: #535353;line-height: 1.5em;">
            {{ item.secondTitle }}
          </div>
          <!--     视频播放     -->
          <div v-if="item.videoUrl !== ''" style="margin-bottom: 5px;margin-top: 5px">
            <!--      播放器区域      -->
            <div>
              <iframe style="width: 350px;height: 200px" :src="item.bilibili" scrolling="no"
                      border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
            </div>
          </div>
          <div class="bottom_text text" :class="isShow ? 'text_isTrue' : ''">
            <div v-for="(txt,txtIndex) in item.textList" :key="txtIndex" @click="isShow = !isShow">
              {{ txt }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserSaid",
  created() {
  },
  data() {
    return {
      isShow: false,
      activeNames: ['1'],
      videoList: [
        {
          title: "春秋茶楼",
          videoUrl: "春秋茶楼魏鸿阳.mp4",
          bilibili: "//player.bilibili.com/player.html?bvid=BV1VA411o7Za&page=1",
          secondTitle: "春秋茶楼拥有五家直营店、三家加盟店包含多种计费模式、存取茶等相关业务",
          tips: "文字版",
          textList: [
            "我们已经开到第五家分店了，使用金米天成管理系统，门店连锁管理给我们提供了很大便捷。",
            "总部可以精细化的管理每家分店，我们不在店里随时可以通过手机端或电脑端，查看各分店营业情况，通过销售看板,可直观的看到总营业分析、商品销售排行及各分店的营业数据。",
            "总部统一管理商品信息，可以查看各分店实时库存情况，根据分店销售情况，便于我们及时采购库存，或多店库存之间进行调拨。",
            "我们都是直营店，多店之间会员共享，给我们提供了很大的帮助，会员到任意一家门店都可以享受会员权益，消费消息实时推送，消费明细有迹可查，让顾客在门店消费的安心、放心。我们不管什么时间反馈的问题，金米天成客服都能及时回复、解决，还有定期的线上培训，帮助我们的员工快速上手。金米天成系统值得拥有，选茶行业管理系统，就用金米天成。"
          ]
        },
        {
          title: "明德嘉业",
          bilibili: "//player.bilibili.com/player.html?bvid=BV1hP4y167zc&page=1",
          videoUrl: "明德嘉业创始人 李红兵.mp4",
        },
        {
          title: "峟茶齐迹",
          bilibili: "//player.bilibili.com/player.html?bvid=BV1vG4y1C7wT&page=1",
          videoUrl: "峟茶齐迹.mp4",
          text: ""
        },
        {
          title: "三味茶屋",
          bilibili: "//player.bilibili.com/player.html?bvid=BV1B84y1b7bL&page=1",
          videoUrl: "三味茶屋刘公子.mp4",
        },
        {
          title: "溢茗坊",
          bilibili: "//player.bilibili.com/player.html?bvid=BV1a84y1b7gT&page=1",
          videoUrl: "溢茗坊李诗航竖版.mp4",
        },
        {
          title: "观味茶香",
          bilibili: "//player.bilibili.com/player.html?bvid=BV1bv4y1179J&page=1",
          videoUrl: "观味茶香 刘卓.mp4",
        },
        {
          title: "雨林华贸",
          bilibili: "//player.bilibili.com/player.html?bvid=BV1Vx4y1u73T&page=1",
          videoUrl: "雨林华贸店樊非凡.mp4",
        },
        {
          title: "香格里茶楼",
          bilibili: "//player.bilibili.com/player.html?bvid=BV12x4y1u7TK&page=1",
          videoUrl: "香格里茶楼.mp4",
        }, {
          title: "茶馆办",
          bilibili: "//player.bilibili.com/player.html?bvid=BV1bx4y1u7vc&page=1",
          videoUrl: "茶馆办李守望主任介绍 茶馆办.mp4",
        }
      ],
      old_id: "",
    }
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    zhanKai(v) {
      this.isShow = !this.isShow;
    }
  }
}
</script>

<style>
body {
  overflow-x: unset;
}

.content {
  color: black;
  /*background: url('@/assets/video/茶背景图2.jpg') no-repeat;*/
  margin: 0 auto 100px;
}

.card_title div:first-child {
  width: 100%;
  margin: 0 auto;
}

.content_top {
  margin-top: 10px;
  /*margin-bottom: 30px;*/
}

.header div:first-child {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  height: 40px;
  font-size: 25px
}

.secondTittle {
  width: auto;
  margin: 0 auto;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  color: #535353;
}

.line {
  width: 300px;
  margin: 10px auto;
  border-bottom: 5px solid #57b05b
}

.bottom_text {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 auto;
  text-align: left;
  text-indent: 2em;
  font-size: 18px;
  color: #535353;
  line-height: 1.5em
}


.text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.text_isTrue {
  display: block !important;
}

.text::before {
  content: '';
  float: right;
  width: 0; /*设置为0，或者不设置宽度*/
  height: 50px; /*先随便设置一个高度*/
}

.btn {
  float: right;
  /*其他装饰样式*/
  width: 15px;
  margin-top: 50px;
}
</style>